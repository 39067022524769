import moment from 'moment-timezone';
import { FC, useState } from 'react';
import { useParams } from 'react-router';
import { INGEST_API_METRIC } from '../../../feature/graph-configuration';
import {
  MonitoringDateRange,
  calculateStep,
  calculateUnit,
  getDefaultMonitoringDateRange,
  getMonitoringDateRangeByTimeComboOption,
} from '../../monitoring/DateRange-Dropdown-util';
import MonitoringGraphs from './MonitoringGraphs';

// @tecton
import { CardGridLayout, FullWidthCard } from '@tecton/ComponentRedesign';
import TectonTimeComboSelector, {
  TimeComboDateRange,
  TimeComboOptionType,
} from '@tecton/ComponentRedesign/lib/TimeComboSelector';
import { filterMonitoringTimeComboOptionCallback } from './monitoring-utils';

const StreamIngestMonitoring: FC = () => {
  const { workspace } = useParams();
  const defaultMonitoringDateRange: MonitoringDateRange = getDefaultMonitoringDateRange();
  const [selectedTimeComboOption, setSelectedTimeComboOption] = useState<TimeComboOptionType>('1Day');
  const [monitoringDateRange, setMonitoringDateRange] = useState<MonitoringDateRange>(defaultMonitoringDateRange);

  const [dateRange, setDateRange] = useState<TimeComboDateRange>({
    startDate: moment().add(-1, 'day'),
    endDate: moment(),
  });

  return (
    <>
      <CardGridLayout>
        <FullWidthCard>
          <TectonTimeComboSelector
            limitMinDateInMonths={2}
            filterOptions={filterMonitoringTimeComboOptionCallback}
            dateRange={dateRange}
            setDateRange={setDateRange}
            initialOption={selectedTimeComboOption}
            onCustomDateSelection={(startDate, endDate) => {
              const startTime = () => startDate.valueOf();
              const endTime = () => endDate.valueOf();
              if (endDate) {
                const step = calculateStep(startDate, endDate);
                const unit = calculateUnit(startDate, endDate);

                const customDateRange: MonitoringDateRange = {
                  startTime: startTime,
                  endTime: endTime,
                  requestId: `${startTime()}${endTime()}`,
                  step: step,
                  unit: unit,
                };
                setSelectedTimeComboOption('custom');
                setMonitoringDateRange(customDateRange);
              }
            }}
            onComboSelection={(timeComboOption) => {
              setSelectedTimeComboOption(timeComboOption);
              const monitoringDateRangeOption = getMonitoringDateRangeByTimeComboOption(timeComboOption);
              setMonitoringDateRange(monitoringDateRangeOption);
            }}
          />
        </FullWidthCard>
      </CardGridLayout>
      <MonitoringGraphs
        monitoringDateRange={monitoringDateRange}
        graphsData={INGEST_API_METRIC}
        customGraphRecord={{}}
        workspace={workspace ?? ''}
      />
    </>
  );
};

export default StreamIngestMonitoring;
