import React, { FC } from 'react';
import { TransformationFCO, TransformationFCOFields } from '../../../core/types/fcoTypes';

import {
  FCOCard,
  CardGridLayout,
  TwoCardRow,
  FCOTagsCard,
  FCOMetadataCard,
  ViewDefinitionFlyout,
} from '@tecton/ComponentRedesign';

interface TransformationOverviewProps {
  transformation: TransformationFCO;
}

const TransformationConfigCard: FC<TransformationOverviewProps> = ({ transformation }) => {
  const items = [
    {
      title: <>Mode</>,
      content: <>{transformation[TransformationFCOFields.MODE]}</>,
    },
    {
      title: <>Transformation Function</>,
      content: (
        <>
          <ViewDefinitionFlyout
            type="Transformation Function"
            definition={transformation[TransformationFCOFields.CODE] ?? ''}
          />
        </>
      ),
    },
  ];
  return <FCOCard title="Configuration" items={items} />;
};

const TransformationOverview: FC<TransformationOverviewProps> = ({ transformation }) => {
  return (
    <CardGridLayout>
      <TwoCardRow>
        <FCOMetadataCard fco={transformation} />
        <FCOTagsCard fco={transformation} />
        <TransformationConfigCard transformation={transformation} />
      </TwoCardRow>
    </CardGridLayout>
  );
};

export default TransformationOverview;
