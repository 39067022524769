import { timeDays } from 'd3-time';
import React, { FC, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

// Tecton
import { useGetFcos } from '../../../api/fcos';
import {
  FCOType,
  FeatureViewFCO,
  FeatureViewFCOFields,
  FeatureViewFCOType,
  WorkspaceFCOContainer,
} from '../../../core/types/fcoTypes';
import { useUserSettings } from '../../context/UserSettingsContext';
import { FeatureAndMetricMap, MaterializationMetricTypes } from '../../featureViews/DataQualityMetricsTypes';
import FeatureViewDataflowContainer from './FeatureViewDataflowContainer';
import FeatureViewDetailMonitoring from './FeatureViewDetailMonitoring';
import FeatureViewJobs from './FeatureViewJobs';
import FeatureViewOverview from './FeatureViewOverview';
import { FeatureRoutes, getFeatureViewRouteName, getFeatureViewRoutes } from './featureViewUtils';

import {
  Breadcrumbs,
  DataQualityMonitoring,
  EmptyPrompt,
  FlexGroupWrapper,
  FlexItem,
  HeaderLayout,
  LinkIcon,
  Loading,
  RelatedFCOsTable,
  Subtitle,
  Tabs,
  ViewLayout,
} from '@tecton/ComponentRedesign';

import {
  useGetFeatureViewMetricsQuery,
  useGetMetricsAndExpectationsDefinitionsQuery,
  useGetMetricsForFeaturesQuery,
} from '../../../api/qualityMetrics';
import { useWorkspaceList } from '../../../shared/query';
import FeatureViewMaterialization from './FeatureViewMaterialization';

import { ReactComponent as Book } from '@svg/book.svg';
import { useCanShowDataQualityMetrics } from '../../../api/dqm';
import { BatchComputeMode } from '../../../types/tecton_proto/common/compute_mode';
import { TECTON_DOCUMENTATION_LINKS } from '../application-links';
import WorkspaceRootBreadcrumb from '../WorkspaceRootBreadcrumb';
interface FVDataProps extends WorkspaceFCOContainer {
  featureView: FeatureViewFCO;
}

const DQMTabDevelopmentWorkspaceWrapper: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { workspace } = useUserSettings();

  const { data, isLoading: workspacesLoading } = useWorkspaceList();
  if (workspacesLoading) {
    return <Loading />;
  }

  const isDevelopmentWorkspace = data.workspaces.find((findWorkspace: any) => findWorkspace.name === workspace);
  if (!isDevelopmentWorkspace || !isDevelopmentWorkspace.capabilities.materializable === true) {
    return (
      <EmptyPrompt
        variant="search"
        body={<>{workspace} is a development workspace. Data Quality Metrics are only enabled for live workspaces</>}
        title={<>Data Quality Metrics Not Available</>}
      />
    );
  }

  return <>{children}</>;
};

const DQMTabForFeatureViews: FC<{ featureView: any; workspace: string }> = ({ featureView, workspace }) => {
  if (!featureView?.isMaterializationEnabled) {
    return (
      <EmptyPrompt
        variant="storm"
        body={
          <>
            <FlexGroupWrapper gap="xl" direction="column" alignItems="center">
              <FlexItem grow={false}>
                Data Quality Metrics are only available for Batch Feature Views where materialization is enabled.{' '}
                {featureView.name} does not have materialization enabled, so Data Quality Metrics aren't calculated.
              </FlexItem>
              <FlexItem grow={false}>
                <LinkIcon
                  linkName={'Learn More'}
                  href={TECTON_DOCUMENTATION_LINKS.DATA_QUALITY_METRICS}
                  iconType={Book}
                  target="_blank"
                />
              </FlexItem>
            </FlexGroupWrapper>
          </>
        }
        title={<>Data Quality Metrics Not Available</>}
      />
    );
  }
  return <FeatureViewsDQMTab featureView={featureView} workspace={workspace} />;
};

const FeatureViewsDQMTab: FC<{ featureView: any; workspace: string }> = ({ featureView, workspace }) => {
  const dateRange = timeDays(new Date(featureView.materializationStartTimestamp), new Date());

  const name = featureView.name;

  const {
    data: rowCountsData,
    isError: rowCountsIsError,
    isLoading: rowCountsLoading,
  } = useGetFeatureViewMetricsQuery({
    workspace: workspace ?? '',
    featureViewName: featureView.name,
    metric_type: MaterializationMetricTypes.COUNT_ROWS,
    start_time: dateRange[0].toISOString(),
    end_time: dateRange[dateRange.length - 1].toISOString(),
  });

  const {
    data: joinKeysData,
    isError: joinKeysIsError,
    isLoading: joinKeysIsLoading,
  } = useGetFeatureViewMetricsQuery({
    workspace: workspace ?? '',
    featureViewName: name,
    metric_type: MaterializationMetricTypes.COUNT_DISTINCT,
    start_time: dateRange[0].toISOString(),
    end_time: dateRange[dateRange.length - 1].toISOString(),
  });

  const featureMetricTypes: FeatureAndMetricMap = new Map();

  const { data: featureMetricsTypesData, isError: featureMetricsTypesIsError } =
    useGetMetricsAndExpectationsDefinitionsQuery({
      workspace: featureView.workspace,
      feature_view_name: featureView.name,
    });

  const {
    data: featureData,
    isError: featureIsError,
    isLoading: featureDataIsLoading,
  } = useGetMetricsForFeaturesQuery({
    workspace: workspace ?? '',
    featureViewName: name,
    metric_type: MaterializationMetricTypes.COUNT_ZEROS,
    start_time: dateRange[0]?.toISOString(),
    end_time: dateRange[dateRange.length - 1].toISOString(),
  });

  featureData &&
    featureData.features.map((feature) => {
      featureMetricTypes.set(feature.name, [
        MaterializationMetricTypes.COUNT_NULLS,
        MaterializationMetricTypes.COUNT_ZEROS,
        MaterializationMetricTypes.MAX_VALUE,
        MaterializationMetricTypes.AVG_VALUE,
        MaterializationMetricTypes.VAR_SAMPLE,
        MaterializationMetricTypes.STDDEV_SAMPLE,
      ]);
    });

  const isLoading = rowCountsLoading || joinKeysIsLoading || featureDataIsLoading;
  const isError = rowCountsIsError || joinKeysIsError || featureIsError;
  const isNotReady = rowCountsData?.data.length === 0;

  return (
    <DQMTabDevelopmentWorkspaceWrapper>
      <DataQualityMonitoring
        featureView={featureView}
        rowCountsData={rowCountsData}
        joinKeysData={joinKeysData}
        featureData={featureData}
        featureMetricsTypesData={featureMetricsTypesData}
        isLoading={isLoading}
        isError={isError}
        isNotReady={isNotReady}
      />
    </DQMTabDevelopmentWorkspaceWrapper>
  );
};

const FeatureViewOutlet = ({ data, isLoading }: { data?: FVDataProps; isLoading?: boolean }) => {
  /*
    This contains the header portion of the Feature view
    - Breadcrumbs
    - Tabs
    - Outlet to the content
  */
  const navigation = useNavigate();
  const { name } = useParams();
  const { workspace, workspaceDetails } = useUserSettings();
  const location = useLocation();
  const featureRoutes = getFeatureViewRouteName(location.pathname);
  const [selectedTab, setSelectedTab] = useState<FeatureRoutes>(featureRoutes);
  const { data: isDataObservabilityEnabled } = useCanShowDataQualityMetrics(workspace ?? '', name ?? '');

  if (isLoading) {
    return <Loading />;
  }
  const featureView = data?.featureView;

  if (!featureView) {
    return <Loading />;
  }

  // We use this to check the compute engine instead since a cluster can have multiple compute engine: "COMPUTE_ENGINES": "Spark,Rift",
  const isBatchModeRift =
    data.featureView[FeatureViewFCOFields.BATCH_COMPUTE_MODE] === BatchComputeMode.BATCH_COMPUTE_MODE_RIFT; // spark

  const breadcrumbs = (
    <Breadcrumbs
      workspace={<WorkspaceRootBreadcrumb workspace={workspaceDetails} />}
      crumbs={[{ label: <Link to={`../${workspace}/features`}>Feature Views</Link> }, { label: name }]}
    />
  );

  let routeNames = Object.keys(getFeatureViewRoutes());

  if (!featureView?.isMaterializationEnabled) {
    routeNames = routeNames.filter((name) => name.toLowerCase() !== 'jobs');
  }

  if (featureView[FeatureViewFCOFields.FEATURE_VIEW_TYPE] === FeatureViewFCOType.REALTIME) {
    routeNames = routeNames.filter((name) => name.toLowerCase() !== 'materialization');
    routeNames = routeNames.filter((name) => name.toLowerCase() !== 'data quality metrics');
  }

  if (featureView[FeatureViewFCOFields.FEATURE_VIEW_TYPE] === FeatureViewFCOType.FEATURE_TABLE) {
    routeNames = routeNames.filter((name) => name.toLowerCase() !== 'materialization');
    routeNames = routeNames.filter((name) => name.toLowerCase() !== 'data quality metrics');
    routeNames = routeNames.filter((name) => name.toLowerCase() !== 'dataflow');
  }

  // We hide DQM tab when it's Rift
  if (!isDataObservabilityEnabled || isBatchModeRift) {
    routeNames = routeNames.filter((name) => name.toLowerCase() !== 'data quality metrics');
  }

  const tabs = (
    <Tabs
      selectedTab={selectedTab}
      tabs={routeNames}
      setSelectedTab={(tab: FeatureRoutes) => {
        const routes = getFeatureViewRoutes();
        navigation(routes[tab]);
        setSelectedTab(tab);
      }}
    />
  );

  const subtitle = <Subtitle description={featureView?.description ?? 'No description provided.'} />;
  const header = <HeaderLayout breadcrumbs={breadcrumbs} subtitle={subtitle} tabs={tabs} />;

  return <ViewLayout header={header} body={<Outlet />} />;
};

const FeatureViewContainer = () => {
  const { workspace } = useUserSettings();
  const { name } = useParams();
  const { data, isLoading } = useGetFcos(workspace ?? '', {
    select: (data: WorkspaceFCOContainer) => {
      return {
        featureView: data?.featureViews.find((fv) => {
          return fv?.name === name;
        }),
        featureServices: data?.featureServices ?? [],
        featureViews: data?.featureViews ?? [],
        idToFcoMap: data.idToFcoMap,
      };
    },
  });

  if (isLoading) {
    return <></>;
  }

  if (!data?.featureView) {
    // We couldn't find the feature view so we will redirect you to the Feature view lists.
    // This can happen when you switch workspaces and that particular FS detail doesn't exist in the workspace you are switching to.
    return <Navigate to={`../${workspace}/features`} />;
  }

  const featureView = data?.featureView;

  const featureServices = featureView.featureServices.map((id: string) => {
    return data?.idToFcoMap[id];
  });

  const featureViewRoutes = getFeatureViewRoutes();

  return (
    <Routes>
      <Route element={<FeatureViewOutlet data={data} isLoading={isLoading} />}>
        <Route
          index
          element={
            <Navigate
              to={
                featureView[FeatureViewFCOFields.FEATURE_VIEW_TYPE] === FeatureViewFCOType.FEATURE_TABLE
                  ? featureViewRoutes['Metadata']
                  : featureViewRoutes['Dataflow']
              }
              replace
            />
          }
        />
        <Route
          path={featureViewRoutes['Dataflow']}
          element={<FeatureViewDataflowContainer fco={featureView} workspaceData={data} idToFcoMap={data.idToFcoMap} />}
        />
        <Route
          path={featureViewRoutes['Metadata']}
          element={<FeatureViewOverview featureView={featureView} idToFcoMap={data.idToFcoMap} />}
        />
        <Route path={featureViewRoutes['Jobs']} element={<FeatureViewJobs fco={featureView} />} />
        <Route
          path={featureViewRoutes['Materialization']}
          element={<FeatureViewMaterialization featureView={featureView} />}
        />
        <Route
          path={featureViewRoutes['Feature Services']}
          element={
            <RelatedFCOsTable
              fco={featureView}
              featureServices={featureServices}
              type={FCOType.FEATURE_SERVICE}
              workspace={workspace}
            />
          }
        />
        <Route
          path={featureViewRoutes['Monitoring']}
          element={<FeatureViewDetailMonitoring featureview={featureView} />}
        />
        <Route
          path={featureViewRoutes['Data Quality Metrics']}
          element={<DQMTabForFeatureViews featureView={featureView} workspace={workspace as string} />}
        />
      </Route>
    </Routes>
  );
};

export default FeatureViewContainer;
