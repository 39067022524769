import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Text } from '.';
import FlexGroup from './FlexGroup';

interface CardProps {
  children: React.ReactNode;
  title?: React.ReactNode;
}

const StyledCard = styled.div<CardProps>`
  padding: ${({ theme }) => theme.v1.size['5']}px ${({ theme }) => theme.v1.size['6']}px;
  background-color: ${({ theme }) => theme.v1.colors.background.empty};
  border: 1px solid ${({ theme }) => theme.v1.colors.border.default};
  border-radius: ${({ theme }) => theme.v1.size['2']}px;
  width: 100%;
  overflow: hidden;
  user-select: none;
`;

const CardBody = styled.div`
  position: relative;
  height: 100%;
`;

const CardTitle = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.v1.colors.border.default};
  padding-bottom: ${({ theme }) => theme.v1.size['2']}px;
`;

const Card: FC<CardProps> = ({ title, children }) => {
  return (
    <StyledCard data-testid="card">
      <FlexGroup gapSize="5" direction="column">
        {title && (
          <CardTitle>
            <Text element="h4">{title}</Text>
          </CardTitle>
        )}
        <CardBody>{children}</CardBody>
      </FlexGroup>
    </StyledCard>
  );
};

export default Card;
