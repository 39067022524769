import styled from '@emotion/styled';
import React, { FC } from 'react';
import FlexGroup from './FlexGroup';
import FlexItem from './FlexItem';

interface ColumnLayoutProps {
  children: React.ReactNode;
}

const ColumnLayoutWrapper = styled.div`
  display: grid;

  grid-template-columns: repeat(1, 1fr);

  gap: ${({ theme }) => theme.v1.size['2']}px;

  @container (min-width: ${({ theme }) => theme.v1.viewports.narrow}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @container (min-width: ${({ theme }) => theme.v1.viewports.regular}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const GridParent = styled.div`
  container-type: inline-size;
  resize: none;
  overflow: auto;
  width: 100%;
  height: 100%;
`;

const Contents = styled.div`
  display: contents;
`;

const FlyinPortal = styled.div`
  height: 100%;
  overflow: hidden;
  width: auto;
`;

const ColumnLayout: FC<ColumnLayoutProps> = ({ children }) => {
  return (
    <FlexGroup padding="0" gapSize="0" data-testid="column-layout">
      <Contents>
        <GridParent>
          <ColumnLayoutWrapper>{children}</ColumnLayoutWrapper>
        </GridParent>
      </Contents>
      <FlexItem grow={'1'} shrink="0">
        <FlyinPortal id="flyin-portal" />
      </FlexItem>
    </FlexGroup>
  );
};

export default ColumnLayout;
